<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import Tablevideo from '@components/video/tablevideo'

// import PageHeader from '@components/page-header'
// import axios from '@/axios'

/**
 * Contents component
 */
export default {
  page: {
    title: 'workout',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    Tablevideo,
    //  PageHeader
  },
  data() {
    return {
      title: 'workout',

      category_code: 'M002',
    }
  },
  methods: {},
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Muaythai Workout</h4>
      </div>
      <div class="col-md-3 col-xl-6">
        <router-link :to="`/category/workout/video/create/${this.$route.params.id}`">
          <button class="btn btn-primary rounded-pill width-lg float-right aligncenter">
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <Tablevideo :sub_id="this.$route.params.id"></Tablevideo>
        </div>
      </div>
    </div>
  </Layout>
</template>
